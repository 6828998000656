import React from 'react';
import TemporaryMessage from './TemporaryMessage';

//TODO: Update to functional component and use useRef
export default class SavedMessage extends React.Component {
  saved() {
    this.temporaryMessage.show();
  }

  reset() {
    this.temporaryMessage.reset();
  }

  render() {
    return (
      <TemporaryMessage
        className="saved"
        message="Saved!"
        ref={(input) => (this.temporaryMessage = input)}
      />
    );
  }
}
