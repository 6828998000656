import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import CheckboxTextfield from './CheckboxTextfield';
import CustomCheckbox from './CustomCheckbox';
import DetailLink from 'react/shared/card/components/DetailLink';
import SavedMessage from 'react/shared/components/SavedMessage';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class CheckboxLine extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  componentDidMount() {
    DashboardRulesetStore.on(
      `account.notificationPreferences.${this.props.name}.updated`,
      this.flashSave,
    );
  }

  componentWillUnmount() {
    DashboardRulesetStore.off(
      `account.notificationPreferences.${this.props.name}.updated`,
      this.flashSave,
    );
  }

  flashSave() {
    this.saved.saved();
  }

  // Determine value. If textfield doesn't exist value is 'on'. Value is always '' if box is unchecked
  determineValue(checked) {
    const amount = ReactDOM.findDOMNode(this.amount);
    let value;

    if (!this.props.textfield) {
      value = 'on'; // No textfield exists
    } else if (amount.value.replace(/ /g, '') === '') {
      value = ''; // Textfield exists but is blank
    } else if (amount) {
      value = Number.parseFloat(amount.value.replace(/[^0-9.]/g, '') || 0).toFixed(0); // Textfield has a numerical value, round to nearest whole number
      this.amount.value = value;
    }

    if (!checked) {
      value = ''; // If the checkbox is not checked, value is always empty string ''
    }

    return value;
  }

  handleChange(el) {
    let data = {};
    const name = el.name;
    const checked = el.checked;
    // Checkboxes either use a name (and optionally a textfield), or a rules object
    if (name) {
      data[name] = this.determineValue(checked);
    } else {
      data = this.props.rules;
    }

    // Call callback and updateChecked/updateUnchecked functions
    if (checked && data[name] !== '') {
      this.props.checkedCallback && this.props.checkedCallback(this);
      this.props.updateChecked(data, this);
    } else {
      this.props.uncheckedCallback && this.props.uncheckedCallback(this);
      this.props.updateUnChecked(data, this);
    }
  }

  // Handles click event, passing target element to handleChange
  handleClick(ev) {
    if (!this.props.disabled) {
      this.handleChange(ev.target);
    }
  }

  // Hitting enter in textfield checks the box if its not checked, updates state regardless
  handleTextfieldEnter() {
    if (this.props.isChecked) {
      this.handleChange(this.checkbox.getUnderlyingInput()); // handleChange needs the underlying checkbox element to work (the thing that CustomCheckbox is a wrapper around)
    } else {
      $(this.checkbox).click();
    }
  }

  // Blurring out of textfield only updates state if checkbox is checked
  handleTextfieldBlur() {
    if (this.props.isChecked) this.handleChange(this.checkbox.getUnderlyingInput());
  }

  render() {
    const divClassname =
      this.props.aboveOverlay && this.props.isChecked
        ? 'above_overlay custom-checkbox-line'
        : 'custom-checkbox-line'; // Used by checkboxes in the temporary overrides section
    const textfield = this.props.textfield && (
      <CheckboxTextfield
        disabled={this.props.disabled}
        onBlur={this.handleTextfieldBlur}
        onEnter={this.handleTextfieldEnter}
        ref={(input) => (this.amount = input)}
        textfieldValue={this.props.textfield}
      />
    );

    let text = [
      <b key="text-1">{this.props.text1}</b>,
      <span key="text-2">{this.props.text2}</span>,
    ];

    if (textfield) {
      text = (
        /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
        <label style={{ display: 'inline' }}>
          {' '}
          {text} {textfield}{' '}
        </label>
      );
    }

    if (this.props.hidden) {
      return null;
    }
    return (
      <div className={divClassname}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="checkbox_label" id={this.props.id}>
          <CustomCheckbox
            checked={this.props.isChecked}
            disabled={this.props.disabled}
            handleHover={this.props.handleHover}
            id={this.props.id}
            name={this.props.name}
            onChange={this.handleClick}
            ref={(input) => (this.checkbox = input)}
            setupHover={this.props.setupHover}
            toolTipTitle={this.props.toolTipTitle}
          />
          {text}
        </label>
        <DetailLink detailText={this.props.detailText} />
        <SavedMessage ref={(input) => (this.saved = input)} />
        <hr className={this.props.hr ? '' : 'hidden'} />
      </div>
    );
  }
}

CheckboxLine.propTypes = {
  // Required properties
  updateChecked: PropTypes.func.isRequired,
  updateUnChecked: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  text2: PropTypes.string.isRequired,

  // Optional properties
  checkedCallback: PropTypes.func,
  detailText: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  hr: PropTypes.bool,
  id: PropTypes.string,
  rules: PropTypes.object,
  name: PropTypes.string,
  textfield: PropTypes.string,
  text1: PropTypes.string,
  aboveOverlay: PropTypes.bool,
  setupHover: PropTypes.func,
  handleHover: PropTypes.func,
  toolTipTitle: PropTypes.string,
  uncheckedCallback: PropTypes.func,
};

CheckboxLine.defaultProps = {
  hr: true,
};
