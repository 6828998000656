import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TransferDescriptionLite from 'react/member/card/components/funding_tab/transfers_table/TransferDescriptionLite';
import { transferShape } from 'react/member/card/shapes/TransferShape';
import { isRecurring } from 'react/member/utils/Transfer';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkRadios from 'react/shared/components/true_link/main/form/radios/TrueLinkRadios';

const useStyles = makeStyles({
  body: {
    fontSize: '20px',
  },
  continueBtn: {
    width: '100%',
  },
  formData: {
    marginTop: '20px',
    '& label': {
      marginBottom: '8px',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '30px',
  },
  description: {
    paddingLeft: '30px',
  },
});

export default function RecurringTransferEditOptionsModal({
  onClose,
  onEditInstance,
  onEditSeries,
  transfer,
}) {
  // Hooks
  // -----------------------------------------------------
  const classes = useStyles();
  const [option, setOption] = useState('');

  // Guards
  // -----------------------------------------------------
  if (!isRecurring(transfer)) return null;

  // Handlers
  // -----------------------------------------------------
  const handleSetOption = (ev) => setOption(ev.target.value);

  const handleContinue = () => {
    if (option === 'series') {
      onEditSeries();
    }
    if (option === 'instance') {
      onEditInstance();
    }
  };

  const options = [
    {
      label: `The next transfer for ${moment(transfer.date).format('MMMM Do')}`,
      value: 'instance',
      id: 'option_instance',
    },
    {
      label: 'The entire series including the next transfer',
      value: 'series',
      id: 'option_series',
    },
  ];

  return (
    <PopUp header="Edit recurring transfer" hideFooter maxWidth="552px" modalOpen onClose={onClose}>
      <div className={classes.body}>
        <div className={classes.description}>
          <TransferDescriptionLite item={transfer} />
        </div>
        <div className={classes.formData}>
          <p>What would you like to edit?</p>

          <TrueLinkRadios
            ariaLabel="What would you like to edit?<"
            name="editOption"
            noBorder
            onChange={handleSetOption}
            options={options}
            size="small"
          />
        </div>
        <div className={classes.footer}>
          <TrueLinkButton
            className={classes.continueBtn}
            disabled={option === ''}
            onClick={handleContinue}
            variant="primary"
          >
            Continue
          </TrueLinkButton>
        </div>
      </div>
    </PopUp>
  );
}

RecurringTransferEditOptionsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEditInstance: PropTypes.func.isRequired,
  onEditSeries: PropTypes.func.isRequired,
  transfer: transferShape.isRequired,
};
