import { ThemeProvider } from '@mui/material/styles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import CountryOfCitizenship from 'react/shared/components/true_link/main/form/country_of_citizenship/CountryOfCitizenship';
import theme from 'react/shared/theme/Theme';
import schema from 'react/shared/utils/schemas';

export default function CountryOfCitizenshipFormWrapper({
  compactUI,
  placeholder,
  hideHeader,
  boldLabel,
}) {
  const countryOfCitizenshipLabel = 'Cardholder Country of Citizenship';
  const nonResidentAlienLabel = `Is the Cardholder a non-resident alien?`;
  /* istanbul ignore next */
  const validationSchema = Yup.object().shape({
    countryOfCitizenship: schema.countryOfCitizenship.required(),
    nonResidentAlien: Yup.string().when(['countryOfCitizenship'], {
      is: (countryOfCitizenship) => countryOfCitizenship && countryOfCitizenship !== 'US',
      then: () => schema.nonResidentAlien.required(),
      otherwise: () => schema.nonResidentAlien.nullable(),
    }),
  });

  const initialValues = {
    countryOfCitizenship: '',
    nonResidentAlien: '',
  };

  return (
    <ThemeProvider theme={theme}>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleChange, setFieldTouched, values }) => (
          <CountryOfCitizenship
            boldLabel={boldLabel}
            compactUI={compactUI}
            countryOfCitizenshipLabel={countryOfCitizenshipLabel}
            hideHeader={hideHeader}
            nonResidentAlienLabel={nonResidentAlienLabel}
            onChange={handleChange}
            placeholder={placeholder}
            setFieldTouched={setFieldTouched}
            values={values}
          />
        )}
      </Formik>
    </ThemeProvider>
  );
}

CountryOfCitizenshipFormWrapper.propTypes = {
  compactUI: PropTypes.bool,
  hideHeader: PropTypes.bool,
  boldLabel: PropTypes.bool,
  placeholder: PropTypes.string,
};
