import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import VerifyUserSvg from 'images/cardholder-idv-icon.svg';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import TrueLinkMaskedTextInput from 'react/shared/components/true_link/main/form/masked_text_input/TrueLinkMaskedTextInput';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';
import { possessiverize } from 'react/shared/utils/Strings';
import schema from 'react/shared/utils/schemas';

const useStyles = makeStyles(() => ({
  idvModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',

    '& .MuiInput-root.retryError': {
      borderColor: PALETTE.red,
    },
    '& .fa-circle-question': {
      opacity: '0.5',
    },
  },
  verifyUserSvg: {
    width: '72px',
  },
  submitBtn: {
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  errorMessage: {
    padding: '18px 24px',
    borderRadius: '6px',
    border: `1px solid`,
    borderColor: PALETTE.red,
    background: PALETTE.redLight,
  },
}));

export default function IdvForm({ firstName, isLoading, lastName, onSubmit, showRetryError }) {
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    ssn: schema.ssn.required(),
    dob: schema.dateOfBirth.required(),
  });

  const tooltipContent = (
    <Typography variant="body2">
      Federal regulation requires us to gather this information. It will only be used to verify the
      cardholder’s identity and will not impact their credit score.
    </Typography>
  );

  const initialValues = {
    ssn: '',
    dob: '',
  };

  let cardholder = 'Cardholder';
  if (firstName && lastName) {
    cardholder = `${firstName} ${possessiverize(lastName)}`;
  }

  return (
    <form className={classes.idvModal} id="idv-form">
      <img alt="verify user" className={classes.verifyUserSvg} src={VerifyUserSvg} />
      <div>
        <Typography variant="h4">Verify {possessiverize(firstName)} Identity</Typography>
        <Typography component="div" variant="body1">
          This is required to add funds and to activate the card, and will not run a credit check.{' '}
          <TrueLinkTooltip placement="right" tooltipContent={tooltipContent}>
            <span id={'tooltipIdvContent'}>Why do we need this? </span>
            <i className="fa-light fa-circle-question" />
          </TrueLinkTooltip>
        </Typography>
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleChange, handleSubmit, setFieldTouched, values }) => (
          <>
            <TrueLinkMaskedTextInput
              className={showRetryError ? 'retryError' : ''}
              labelText={`${cardholder} Social Security Number`}
              mask="999-99-9999"
              name="ssn"
              onChange={handleChange}
              placeholder="XXX-XX-XXXX"
              required
              setFieldTouched={setFieldTouched}
              value={values.ssn}
            />
            <TrueLinkMaskedTextInput
              className={showRetryError ? 'retryError' : ''}
              labelText={`${cardholder} Date of Birth`}
              mask="99/99/9999"
              name="dob"
              onChange={handleChange}
              placeholder="MM/DD/YYYY"
              required
              setFieldTouched={setFieldTouched}
              value={values.dob}
            />
            {showRetryError && (
              <div className={classes.errorMessage}>
                Please check that you have entered the correct information for cardholder{' '}
                {firstName} {lastName}, which is typically different than your own.
              </div>
            )}
            <TrueLinkButton
              className={classes.submitBtn}
              loading={isLoading}
              onClick={handleSubmit}
              type="submit"
              variant="primary"
            >
              Submit
            </TrueLinkButton>
          </>
        )}
      </Formik>
      <Typography variant="caption">
        To help the federal government fight the funding of terrorism and money laundering
        activities, the USA PATRIOT Act requires all financial institutions and their third parties
        to obtain, verify and record information that identifies each person who opens a card
        account. What this means for you: When you open a card account, we will ask for your name,
        address, date of birth, and other identifying information that will allow us to identify
        you. We may also ask to see your driver's license or other identifying documents.
      </Typography>
    </form>
  );
}

IdvForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showRetryError: PropTypes.bool,
};
