import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import CopyButton from 'react/jaffe/card/components/CopyButton';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/text_input/TrueLinkTextInput';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px 0',
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
  },
  subHeader: {
    color: PALETTE.grey2,
  },
  inputField: {
    marginTop: '10px',
  },
  previewBtn: {
    marginLeft: '10px',
  },
  sendEmailActionBtns: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },

  modal: {
    '& .actionBtns': {
      textAlign: 'center',
    },
    '& .emailPreviewContainer': {
      margin: '10px',
      border: '1px solid grey',
      borderRadius: '5px',
      maxHeight: '65vh',
      minHeight: '100px',
      overflow: 'scroll',
    },
  },
  spinner: {
    width: '100px',
    height: '100px',
  },
}));

export default function ReferralIncentivesForm({
  resetForm,
  onChange,
  setFieldTouched,
  referralSlug,
  values,
}) {
  const [showModal, setShowModal] = useState(false);
  const [emailPreview, setEmailPreview] = useState(false);

  const classes = useStyles();
  const url = RailsRoutes.api_v2_referral_referral_emails_path(referralSlug);
  const data = {
    data: {
      attributes: {
        email: values.email,
      },
    },
  };

  useEffect(() => {
    if (!emailPreview) return;
    const link = document.getElementById('get_started_btn');
    if (link) {
      link.removeAttribute('href');
      link.setAttribute('disabled', 'true');
    }
  }, [emailPreview]);

  const { mutate: sendReferralEmail } = useMutation({
    mutationFn: () => axios.post(url, data),
    onSuccess: () => {
      Truelink.flash('success', `Email successfully sent`);
      window.setTimeout(Truelink.flashClear, 3000);
      resetForm();
    },
    onError: (error) => {
      if (error.response.status == 404) {
        Truelink.flash('error', `Referral not found`);
        window.setTimeout(Truelink.flashClear, 3000);
      }
    },
  });

  const { mutate: getReferralEmailPreview, isLoading: emailPreviewLoading } = useMutation({
    mutationFn: () => axios.get(RailsRoutes.referral_preview_path(referralSlug)),
    onSuccess: (response) => {
      setEmailPreview(response.data);
    },
    onError: (error) => {
      setEmailPreview(error.message);
    },
  });

  const previewEmail = () => {
    setShowModal(true);
    getReferralEmailPreview();
  };

  const closePreview = () => {
    setShowModal(false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="body1">
        <b>Share referral link by email</b>
      </Typography>
      <Typography className={classes.subHeader} variant="body1">
        We'll send them an email with instructions on how to get started
      </Typography>
      <TrueLinkTextInput
        className={classes.inputField}
        name="email"
        onChange={onChange}
        placeholder="Enter email address"
        setFieldTouched={setFieldTouched}
        value={values.email}
      />
      <div className={classes.sendEmailActionBtns}>
        <TrueLinkButton onClick={sendReferralEmail} variant="primary">
          Send
        </TrueLinkButton>
        <TrueLinkLink className={classes.previewBtn} onClick={previewEmail}>
          Preview email
        </TrueLinkLink>
      </div>
      <br />
      <Typography variant="body1">
        <b>You can share your personal link:</b>
      </Typography>
      <Grid alignItems="center" container justifyContent="center">
        <Grid item sm={10} style={{ paddingRight: '10px' }} xs={12}>
          <TrueLinkTextInput
            className={classes.inputField}
            name="referralUrl"
            value={values.referralUrl}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <CopyButton
            copyText={values.referralUrl}
            text="Copy Link"
            title="Copy Link"
            variant="primary"
          />
        </Grid>
      </Grid>
      {showModal && (
        <TrueLinkModal modalProps={{ className: classes.modal }} title={<b>Preview Email</b>}>
          {emailPreview && (
            <div
              className="emailPreviewContainer"
              /* Disabling since this is coming from an internal API */
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: emailPreview }}
            />
          )}
          {emailPreviewLoading && (
            <div className="emailPreviewContainer">
              <LoadingIndicator
                containerStyle={{ margin: 50, width: '460px' }}
                spinnerClass={classes.spinner}
              />
            </div>
          )}
          <div className="actionBtns">
            <TrueLinkButton onClick={closePreview} type="submit" variant="primary">
              Done
            </TrueLinkButton>
          </div>
        </TrueLinkModal>
      )}
    </div>
  );
}

ReferralIncentivesForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  referralSlug: PropTypes.string,
  values: PropTypes.shape({
    email: PropTypes.string,
    referralUrl: PropTypes.string.isRequired,
  }).isRequired,
};
