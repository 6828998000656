import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useAsyncDebounce } from 'react-table';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/text_input/TrueLinkTextInput';

export default function ApprovedDisbursementsFilters({
  globalFilter,
  setGlobalFilter,
  headerGroups,
  setAllFilters,
  filterType,
}) {
  const [value, setValue] = useState(globalFilter);
  const handleClear = () => {
    setValue('');
    setGlobalFilter('');
    setAllFilters([]);
  };
  const classes = makeStyles({
    searchInput: {
      height: '36px',
      marginBottom: 0,
      padding: 0,
    },
  });
  const onChange = useAsyncDebounce(setGlobalFilter, 200);
  const [open, setOpen] = useState(false);

  let filterTableTitle = 'Approved Disbursements';
  let filterTableSearchId = 'approved-disbursements-search';

  if (filterType === 'pending') {
    filterTableTitle = 'Disbursements Pending Approval';
    filterTableSearchId = 'pending-disbursements-search';
  }
  if (filterType === 'additional approval') {
    filterTableTitle = 'Disbursements Pending Additional Approval';
    filterTableSearchId = 'pending-additional-approval-search';
  }

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const searchParams = new window.URLSearchParams(window.location.search);
    const disbursementIdFilter = searchParams.get('disbursement_id');
    if (disbursementIdFilter !== null) {
      setValue(disbursementIdFilter);
      onChange(disbursementIdFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '15px',
          borderBottom: '1px solid rgb(189, 189, 189)',
        }}
      >
        <Typography style={{ fontSize: '24px', lineHeight: '31px' }} variant="body1">
          {filterTableTitle}
        </Typography>
        <TrueLinkTextInput
          className={classes.searchInput}
          inputProps={{
            id: filterTableSearchId,
          }}
          isFormik={false}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          size="small"
          value={value || ''}
        />
      </div>
      <div
        className="table-filters"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {headerGroups?.map((headerGroup, i) => (
          <div
            key={i}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px' }}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, idx) => (
              <div key={`col-${idx}`} {...column.getHeaderProps()}>
                {column.canFilter ? column.render('Filter', { open, setOpen }) : null}
              </div>
            ))}
          </div>
        ))}
        <div style={{ marginLeft: '15px' }}>
          <TrueLinkLink onClick={handleClear} size="small" variant="primary">
            Clear All
          </TrueLinkLink>
        </div>
      </div>
    </>
  );
}

ApprovedDisbursementsFilters.propTypes = {
  setGlobalFilter: PropTypes.func,
  globalFilter: PropTypes.string,
  setAllFilters: PropTypes.func,
  headerGroups: PropTypes.array,
  rangeFilterable: PropTypes.bool,
  filterType: PropTypes.string,
};
