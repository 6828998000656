import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import _each from 'underscore/modules/each';
import _extend from 'underscore/modules/extend';
import _map from 'underscore/modules/map';
import _omit from 'underscore/modules/omit';
import TemporaryMessage from 'react/shared/components/TemporaryMessage';
import Field from 'react/shared/components/forms/Field';
import FieldMixin from 'react/shared/components/forms/FieldMixin';
// There are quite a few attachment components in our code base. This one may or may not be the one you want!
// This is currently set up to use the "Field" mixin, so it works well in Admin views that use the "Controller" mixin.
// It generally gives you an unstyled file picker, so it isn't ready for customer-facing views yet.
// Ideally, we'd have one attachment component to rule them all, but there are currently a mix of features across all of them that need to be deduped.

//TODO: Update to functional component and use useRef

const AttachmentsField = createReactClass({
  propTypes: {
    // This should be a Route we can use to fetch attachments from
    collectionPath: PropTypes.string,
    // This function takes an attachment id and returns the path to this attachment
    filePathFunction: PropTypes.func,
    ajaxOptions: PropTypes.object,
  },

  mixins: [FieldMixin],

  getDefaultProps() {
    return {
      ajaxOptions: {},
    };
  },

  getInitialState() {
    return {
      attachmentsById: {},
      pendingAttachments: {},
      pendingParentSave: !this.props.collectionPath,
    };
  },

  componentDidMount() {
    this.loadAttachments();
  },

  componentDidUpdate(nextProps) {
    // This catches when the parent object is saved and we need to persist attachments
    if (
      !this.props.collectionPath &&
      nextProps.collectionPath &&
      Object.keys(this.state.pendingAttachments).length
    ) {
      this.processUploads(nextProps);
    }
  },

  loadAttachments() {
    // Don't try to load attachments if the parent hasn't persisted yet
    if (this.state.pendingParentSave) {
      return;
    }

    $.get(this.props.collectionPath).done((attachments) => {
      const attachmentsById = {};
      _each(attachments.data, (attachment) => {
        attachmentsById[attachment.id] = { ...attachment.attributes, id: attachment.id };
      });
      this.setState({ attachmentsById });
    });
  },

  processUploads(props) {
    _each(this.state.pendingAttachments, (attachment, key) => {
      $.ajax({
        url: props.collectionPath,
        type: 'POST',
        data: attachment,
        enctype: 'multipart/form-data',
        processData: false,
        contentType: false,
        dataType: 'json',
        ...this.props.ajaxOptions,
      }).done((attachment) => {
        const newAttachment = {};
        newAttachment[attachment.data.id] = {
          ...attachment.data.attributes,
          id: attachment.data.id,
        };

        // Shift the pending attachment into the attachmentsById object
        this.setState({
          attachmentsById: _extend(this.state.attachmentsById, newAttachment),
          pendingAttachments: _omit(this.state.pendingAttachments, key),
        });
      });
    });
  },

  attachmentPath(attachmentId) {
    return this.props.filePathFunction(attachmentId);
  },

  upload() {
    if (ReactDOM.findDOMNode(this.content).value != '') {
      const file = ReactDOM.findDOMNode(this.content).files[0];

      const formData = new window.FormData();
      formData.append('attachment[file]', file);

      const newAttachment = {};
      newAttachment[file.name] = formData;

      this.setState({ pendingAttachments: _extend(this.state.pendingAttachments, newAttachment) });
      ReactDOM.findDOMNode(this.uploadForm).reset();

      if (this.props.collectionPath) {
        this.processUploads(this.props);
      }
    }
  },

  destroy(attachmentId, pending = false) {
    if (pending) {
      this.setState({
        pendingAttachments: _omit(this.state.pendingAttachments, attachmentId),
      });
    } else {
      $.ajax({
        url: this.attachmentPath(attachmentId),
        method: 'DELETE',
        dataType: 'json',
      }).done((_result) => {
        this.destroyed.show();
        this.setState({
          attachmentsById: _omit(this.state.attachmentsById, attachmentId),
        });
      });
    }
  },

  renderShow() {
    const { attachmentsById, pendingAttachments } = this.state;

    const existingLinks = _map(attachmentsById, (attachment, _key) =>
      this.renderAttachment(attachment, false),
    );

    const pendingLinks = _map(pendingAttachments, (_attachment, key) =>
      this.renderAttachment({ name: key }, true),
    );

    let noAttachmentNotice;
    if (!Object.keys(attachmentsById).length && !Object.keys(pendingAttachments).length) {
      noAttachmentNotice = <i>No attachments</i>;
    }
    return (
      <div>
        {noAttachmentNotice}
        {existingLinks}
        {pendingLinks}
        <TemporaryMessage message="Discarded!" ref={(input) => (this.destroy = input)} />
      </div>
    );
  },

  renderSavedAttachment(attachment) {
    return <a href={this.props.filePathFunction(attachment.id)}>{attachment.name}</a>;
  },

  renderPendingAttachment(attachment) {
    return (
      <span>
        <a>{attachment.name}</a> &nbsp; Waiting...
      </span>
    );
  },

  renderAttachment(attachment, pending) {
    const destroyFunc = () => {
      if (pending) {
        this.destroy(attachment.name, true);
      } else {
        this.destroy(attachment.id);
      }
    };

    let destroyIcon;
    if (this.editable()) {
      destroyIcon = (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <a onClick={destroyFunc}>
          <i className="trash" />
        </a>
      );
    }

    let attachmentLink, key;
    if (pending) {
      attachmentLink = this.renderPendingAttachment(attachment);
      key = attachment.name;
    } else {
      attachmentLink = this.renderSavedAttachment(attachment);
      key = attachment.id;
    }

    return (
      <div key={key}>
        {destroyIcon}
        &nbsp;
        {attachmentLink}
      </div>
    );
  },

  renderEdit() {
    let pendingPrompt;
    if (this.state.pendingParentSave) {
      pendingPrompt = <p>Attachments will be saved when the parent record is saved.</p>;
    }

    return (
      <div>
        <form ref={(input) => (this.uploadForm = input)}>
          <div>
            {pendingPrompt}
            <input
              name="attachment-file"
              onChange={this.upload}
              ref={(input) => (this.content = input)}
              type="file"
            />
            <TemporaryMessage message="Uploaded!" ref={(input) => (this.uploaded = input)} />
          </div>
        </form>
        {this.renderShow()}
      </div>
    );
  },

  render() {
    return (
      <Field
        {...this.props}
        editable={this.editable}
        label={this.label}
        renderEdit={this.renderEdit}
        renderShow={this.renderShow}
      />
    );
  },
});

AttachmentsField.propTypes = {
  // This should be a Route we can use to fetch attachments from
  collectionPath: PropTypes.string,
  // This function takes an attachment id and returns the path to this attachment
  filePathFunction: PropTypes.func,
  ajaxOptions: PropTypes.object,
};

export default AttachmentsField;
