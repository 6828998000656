import PropTypes from 'prop-types';
import React from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/text_input/TrueLinkTextInput';

export default function EditTrustDescription({
  handleCloseTrustDescription,
  values,
  handleChange,
  handleSubmitDescription,
}) {
  return (
    <PopUp
      bodyHeight="48vh"
      footer={
        <TrueLinkButton onClick={handleSubmitDescription} size="small" variant="primary">
          Update
        </TrueLinkButton>
      }
      header="Update Trust Description"
      maxHeight="650px"
      maxWidth="700px"
      onClose={handleCloseTrustDescription}
      openModal
      paddingTop="200px"
    >
      <TrueLinkTextInput
        bottomLabelText={`${values.name.length}/${100} Characters`}
        inputProps={{ maxLength: 100 }}
        isFormik={false}
        multiline
        name="trust description"
        onChange={handleChange('name')}
        rows={4}
        value={values.name}
      />
    </PopUp>
  );
}
EditTrustDescription.propTypes = {
  handleCloseTrustDescription: PropTypes.func,
  values: PropTypes.shape({ name: PropTypes.string }),
  handleChange: PropTypes.func,
  handleSubmitDescription: PropTypes.func,
};
