import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { prettyPhone } from 'react/member/utils/Formatting';
import TrueLinkRadios from 'react/shared/components/true_link/main/form/radios/TrueLinkRadios';

const useStyles = makeStyles({
  body: {
    '& p': {
      'padding-bottom': '15px',
    },
    '& label': {
      border: 'none',
    },
  },

  highlight: {
    'font-weight': 'bold',
  },
});

export default function MfaSetupWizardStep1({
  email,
  enable,
  mfaDeliveryOption,
  onChange,
  phoneNumber,
}) {
  const classes = useStyles();

  const options = [
    {
      label: `Text Message: ${phoneNumber && prettyPhone(phoneNumber)}`,
      value: 'sms',
    },
    {
      label: `Email: ${email}`,
      value: 'email',
    },
  ];

  return (
    <div className={classes.body}>
      <span className={classes.highlight}>
        {enable
          ? 'Secure your True Link account with two-step verification'
          : "Secure your account's two-step verification"}
      </span>
      <p>
        {enable
          ? 'This will give you an extra layer of security by requiring you to enter a verification code, in addition to your password, whenever you sign in.'
          : "As an extra layer of security, you'll need to enter a verification code before deactivating two-step verification."}
      </p>
      <span>
        {!enable && mfaDeliveryOption ? (
          <p>{mfaDeliveryOption == 'email' ? email : phoneNumber && prettyPhone(phoneNumber)}</p>
        ) : (
          <>
            <p>Choose your verification method:</p>
            <TrueLinkRadios
              ariaLabel="Choose your verification method:"
              name="mfaOption"
              onChange={onChange}
              options={options}
            />
          </>
        )}
      </span>
    </div>
  );
}

MfaSetupWizardStep1.propTypes = {
  email: PropTypes.string.isRequired,
  enable: PropTypes.bool.isRequired,
  mfaDeliveryOption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};
