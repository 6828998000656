import PropTypes from 'prop-types';
import React from 'react';
import _map from 'underscore/modules/map';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

export default class BackgroundFileGenerationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    bindAll(this);
  }

  toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  downloadFile(e) {
    e.preventDefault();

    this.setState({ modalOpen: true });
    const data = this.props.data;
    const [jsonParams, fileParams] = _map(
      [{ format: 'json' }, { format: this.props.fileType }],
      (format) => $.extend({ generate_file: true }, data, format),
    );

    this.initiateDownload(jsonParams, fileParams);
  }

  initiateDownload(jsonParams, fileParams) {
    let downloadRoute = this.props.downloadRoute;

    if (typeof downloadRoute == 'string') {
      downloadRoute = RailsRoutes[downloadRoute];
    }

    $.ajax({
      url: downloadRoute(jsonParams),
      type: 'GET',
      success: $.proxy((response) => {
        if (response.ready) {
          this.setState({ modalOpen: false }, () => {
            $.extend(fileParams, { time: response.time });
            window.location = downloadRoute(fileParams);
          });
        } else {
          $.extend(jsonParams, { time: response.time });
          window.setTimeout(this.initiateDownload, 1500, jsonParams, fileParams);
        }
      }, this),
    });
  }

  render() {
    const { largeNumberOfAccounts, modalText, downloadDisabled } = this.props;

    return (
      <div>
        {largeNumberOfAccounts && (
          <PopUp dismissBtnText="Close" onClose={this.closeModal} openModal={this.state.modalOpen}>
            <h4>{modalText}</h4>
          </PopUp>
        )}
        <form onSubmit={this.downloadFile} ref={(input) => (this.cardStatusReportForm = input)}>
          <TrueLinkButton
            className="btn btnmini normal"
            disabled={downloadDisabled}
            type="submit"
            variant="none"
          >
            {this.props.buttonText}
          </TrueLinkButton>
        </form>
      </div>
    );
  }
}

BackgroundFileGenerationForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  downloadDisabled: PropTypes.bool.isRequired,
  downloadRoute: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.string.isRequired]),
  fileType: PropTypes.string.isRequired,
  modalText: PropTypes.string.isRequired,
  largeNumberOfAccounts: PropTypes.bool.isRequired,
  data: PropTypes.object,
};
