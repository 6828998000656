import Card from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CancelButton from 'react/shared/components/true_link/main/form/wizard/CancelButton';
import PreviousPageButton from 'react/shared/components/true_link/main/form/wizard/PreviousPageButton';
import childrenShape from 'react/shared/shapes/children';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  buttons: {
    overflow: 'hidden',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0',
    },
  },

  card: {
    '&.MuiCard-root': {
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      padding: 0,
      paddingBottom: '48px',
      [theme.breakpoints.up('md')]: {
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: theme.shape.borderRadius,
        minWidth: '667px',
        padding: '48px',
      },
    },
  },
}));

export default function WizardCard({
  children,
  className,
  showBackButton = true,
  showCancelButton = false,
  onBack,
}) {
  //*****IMPORTANT*******
  // This component must be wrapped in BrowserRouter
  //********************/
  const classes = useStyles();
  const klassName = classNames(classes.card, { [className]: !!className });
  const navigate = useNavigate();

  const handleBack = () => {
    onBack(navigate);
  };

  return (
    <>
      <div className={classes.buttons}>
        {showBackButton && <PreviousPageButton onBack={handleBack} />}
        {showCancelButton && <CancelButton />}
      </div>
      <Card className={klassName}>{children}</Card>
    </>
  );
}

WizardCard.propTypes = {
  children: childrenShape.isRequired,
  className: PropTypes.string,
  onBack: PropTypes.func,
  showBackButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
};
