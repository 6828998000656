import {
  entityTypes,
  originTypes,
  reportTargetTypeOptions,
} from 'react/member/components/dashboard/reports/Constants';

export function getEntityTypeOptions({ origin, report, entityCollections }) {
  if (origin.type !== originTypes.organization) {
    return [];
  }

  let options = [];
  const { supportsSearchBy } = report;
  const exclusiveTrustType = supportsSearchBy.trustType;
  const hasStandaloneTrusts = entityCollections[entityTypes.standaloneTrust]?.length;
  const hasPooledTrusts = entityCollections[entityTypes.pooledTrust]?.length;

  if (supportsSearchBy.organization && hasStandaloneTrusts && hasPooledTrusts) {
    options.push(reportTargetTypeOptions.organization);
  }

  if (hasPooledTrusts && (!exclusiveTrustType || exclusiveTrustType === entityTypes.pooledTrust)) {
    options.push(reportTargetTypeOptions.allPooledTrusts);

    if (supportsSearchBy.trust) {
      options.push(reportTargetTypeOptions.pooledTrust);
    }
  }

  const hasPortfolios = entityCollections[entityTypes.portfolio]?.length;
  if (supportsSearchBy.portfolio && hasPortfolios && hasPooledTrusts) {
    options.push(reportTargetTypeOptions.portfolio);
  }

  if (
    hasStandaloneTrusts &&
    (!exclusiveTrustType || exclusiveTrustType === entityTypes.standaloneTrust)
  ) {
    options.push(reportTargetTypeOptions.allStandaloneTrusts);

    if (supportsSearchBy.trust) {
      options.push(reportTargetTypeOptions.standaloneTrust);
    }
  }

  const hasBeneficiaries = entityCollections[entityTypes.trustBeneficiary]?.length;
  if (supportsSearchBy.beneficiary && hasBeneficiaries) {
    options.push(reportTargetTypeOptions.trustBeneficiary);
  }

  // GeneratePortfolioAssetsByDateCsv does not support filtering by trust type, even though it should only be for pooled trusts
  if (report.type === 'Invest::Reports::GeneratePortfolioAssetsByDateCsv') {
    options.unshift(reportTargetTypeOptions.organization);
    options = options.filter((option) => option !== reportTargetTypeOptions.allPooledTrusts);
  }

  return options;
}
