import PropTypes from 'prop-types';
import React from 'react';
import SavedMessage from 'react/shared/components/SavedMessage';
import { capitalizeWords, humanize } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class AjaxCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.initialChecked,
    };

    bindAll(this);
  }

  label() {
    if (this.props.label != undefined) {
      return this.props.label;
    }

    return capitalizeWords(humanize(this.props.name));
  }

  onChange(evt) {
    const data = {};
    const checked = evt.target.checked;
    data[this.props.name] = checked ? 1 : 0;
    $.ajax({ url: this.props.path, method: 'PUT', data, dataType: 'json' }).done((object) => {
      this.setState({ checked });
      this.saved.saved();
      if (this.props.onChange) {
        this.props.onChange(evt, object);
      }
    });
  }

  render() {
    let fieldClass, fieldStyle;
    if (this.props.inline) {
      fieldClass = 'generic-field generic-field--inline';
      fieldStyle = { minHeight: 0 };
    } else {
      fieldClass = 'generic_field';
      fieldStyle = {};
    }
    return (
      <div className={fieldClass} style={fieldStyle}>
        <div className="generic-field__label" style={{ marginRight: 9 }}>
          <span>{this.label()}</span>:
        </div>
        <div className="generic-field__data">
          <input
            checked={this.state.checked}
            name={this.props.name}
            onChange={this.onChange}
            type="checkbox"
          />
          <SavedMessage ref={(input) => (this.saved = input)} />
        </div>
      </div>
    );
  }
}

AjaxCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  initialChecked: PropTypes.bool,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
};
