import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'react/shared/utils/bind_all';

export default class Toggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
    };

    bindAll(this);
  }

  handleFocus() {
    this.setState({ hasFocus: true });
  }

  handleBlur() {
    this.setState({ hasFocus: false });
  }

  render() {
    const classes = classNames('react-toggle', {
      'react-toggle--checked': !!this.props.checked,
      'react-toggle--focus': this.state.hasFocus,
      'react-toggle--disabled': this.props.disabled,
    });

    return (
      <div className={classes}>
        <div className="react-toggle-track">
          <div className="react-toggle-track-block">
            <div>BLOCK</div>
          </div>
          <div className="react-toggle-track-allow">
            <div>ALLOW</div>
          </div>
        </div>
        <div className="react-toggle-thumb" />

        <input
          className="react-toggle-screenreader-only"
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          ref={(input) => (this.input = input)}
          type="checkbox"
          {...this.props}
        />
      </div>
    );
  }
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
