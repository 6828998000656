import PropTypes from 'prop-types';
import React from 'react';
import SavedMessage from 'react/shared/components/SavedMessage';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class EmailPreferences extends React.Component {
  static get propTypes() {
    const { object, func } = PropTypes;
    return {
      prefs: object.isRequired,
      onUpdatePrefs: func.isRequired,
    };
  }

  constructor(props) {
    super(props);

    bindAll(this);
  }

  componentDidMount() {
    DashboardRulesetStore.on('account.notificationPreferences.digest.updated', this.flashSave);
  }

  componentWillUnmount() {
    DashboardRulesetStore.off('account.notificationPreferences.digest.updated', this.flashSave);
  }

  flashSave() {
    this.saved.saved();
  }

  handleRadioChange(ev) {
    try {
      const { name, value } = ev.target;
      this.props.onUpdatePrefs({ [name]: value });
    } catch {
      ev.preventDefault();
    }
  }

  renderRealTimeRadioLine() {
    return (
      <div id="email-preferences-real-time">
        <label className="radio_label" htmlFor="digest_off">
          <input
            checked={this.props.prefs['digest'] !== 'on'}
            id="digest_off"
            name="digest"
            onChange={this.handleRadioChange}
            type="radio"
            value=""
          />
          &nbsp;&nbsp;In real time, as they occur
        </label>
      </div>
    );
  }

  renderDigestRadioLine() {
    return (
      <div id="email-preferences-digest">
        <label className="radio_label" htmlFor="digest_on">
          <input
            checked={this.props.prefs['digest'] === 'on'}
            id="digest_on"
            name="digest"
            onChange={this.handleRadioChange}
            type="radio"
            value="on"
          />
          &nbsp;&nbsp;In a once-daily digest
        </label>
      </div>
    );
  }

  render() {
    return (
      <div>
        <p>
          <b>Send email notifications:</b>
        </p>
        {this.renderRealTimeRadioLine()}
        {this.renderDigestRadioLine()}
        <div style={{ margin: '5px 10px' }}>
          <SavedMessage ref={(input) => (this.saved = input)} />
        </div>
      </div>
    );
  }
}
