import InputAdornment from '@mui/material/InputAdornment';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import TrueLinkAddressInput from './TrueLinkAddressInput';

export default {
  title: 'Forms/AddressInput',
  component: TrueLinkAddressInput,
  argTypes: {
    onChange: {
      action: 'changed',
      table: {
        category: 'Events',
      },
    },
    setFieldTouched: {
      action: 'touched',
      table: {
        category: 'Events',
      },
    },
    value: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    values: {
      control: 'object',
      table: {
        category: 'Contents',
      },
    },
  },
};

function Template(args) {
  useEffect(() => {
    if (window.google) {
      return;
    }
    const script = document.createElement('script');

    const storybookApiKey = 'AIzaSyCxKxUv2YQca3X635-gTOrffJ9MFhTsRAw';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${storybookApiKey}&libraries=places`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const { initialValues, ...passThroughArgs } = args;
  return (
    <Formik
      initialValues={
        initialValues || { address: { street1: '', street2: '', city: '', state: '', zip: '' } }
      }
    >
      {({ setValues, values }) => {
        const handleFormChange = (newValues) => {
          setValues(newValues);
          args.onChange(newValues);
        };
        return (
          <TrueLinkAddressInput {...passThroughArgs} onChange={handleFormChange} values={values} />
        );
      }}
    </Formik>
  );
}

export const Standard = Template.bind({});
Standard.args = {
  required: false,
};

export const WithPrefix = Template.bind({});
WithPrefix.args = {
  required: false,
  labelPrefix: 'Cardholder',
};

export const Prefilled = Template.bind({});
Prefilled.args = {
  required: false,
  initialValues: {
    address: {
      street1: '1234 Happy St',
      street2: 'Apt 6B',
      city: 'Mountainview',
      state: 'MI',
      zip: '49564',
    },
  },
};

export const Adorned = Template.bind({});
Adorned.args = {
  required: false,
  street1Adornment: <InputAdornment position="end">No PO Boxes</InputAdornment>,
};
