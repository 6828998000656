import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this);
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this))
      .find('#recurring-disbursement-cancellation-date')
      .datepicker({
        dateFormat: 'm/d/yy',
        beforeShowDay: this.beforeShowDay,
        minDate: '+1D',
        maxDate: '+1Y',
      })
      .on('change', this.handleDateSelect);
  }

  isDateSelectable(date) {
    const { recurringDisbursement } = this.props;
    switch (recurringDisbursement.recurring_type) {
      case 'every_n_weeks':
        return date.getDay() === Number.parseInt(recurringDisbursement.day_of_week, 10);
      case 'every_n_months':
        return date.getDate() === Number.parseInt(recurringDisbursement.day_of_month, 10);
      case 'twice_monthly_every_n_months':
        return (
          date.getDate() === Number.parseInt(recurringDisbursement.day_of_month, 10) ||
          date.getDate() === Number.parseInt(recurringDisbursement.day_of_month_2, 10)
        );
      default:
        return false;
    }
  }

  beforeShowDay(date) {
    return [this.isDateSelectable(date), ''];
  }

  handleDateSelect(e) {
    this.props.onDateSelect(e.target.value);
  }

  render() {
    return (
      <div className="datepicker-wrapper">
        <input
          autoComplete="off"
          className="date_input js-date"
          defaultValue={this.props.date}
          id="recurring-disbursement-cancellation-date"
          name="date"
          type="text"
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  date: PropTypes.string,
  onDateSelect: PropTypes.func.isRequired,
  recurringDisbursement: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    day_of_month: PropTypes.number,
    day_of_month_2: PropTypes.number,
    day_of_week: PropTypes.number,
    frequency: PropTypes.number,
    recurring_type: PropTypes.string,
  }).isRequired,
};
