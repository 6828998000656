import React from 'react';
import TrueLinkNumericCode from 'react/shared/components/true_link/main/form/numeric_code/TrueLinkNumericCode';

export default {
  title: 'Forms/NumericCode',
  component: TrueLinkNumericCode,
  argTypes: {
    fields: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    value: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
    isValid: {
      control: 'boolean',
      table: {
        category: 'Contents',
      },
    },
    onChange: {
      table: {
        category: 'Events',
      },
    },
  },
};

function Template(args) {
  return <TrueLinkNumericCode {...args} />;
}

export const Empty = Template.bind({});
Empty.args = {
  fields: 4,
};

export const Prepopulated = Template.bind({});
Prepopulated.args = {
  fields: 4,
  value: '1234',
};

export const Invalid = Template.bind({});
Invalid.args = {
  fields: 7,
  value: '1234',
  isValid: false,
};
