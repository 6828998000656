import { Formik } from 'formik';
import React, { useState } from 'react';
import TrueLinkMaskedTextInput from './TrueLinkMaskedTextInput';

export default {
  title: 'Forms/MaskedTextInput',
  component: TrueLinkMaskedTextInput,
  argTypes: {
    onChange: { action: 'changed' },
    setFieldTouched: { action: 'touched' },
    value: {
      control: 'text',
      table: {
        category: 'Contents',
      },
    },
  },
};

function Template(args) {
  const [value, setValue] = useState('');
  return (
    <Formik initialValues={{ value: '' }}>
      {() => <TrueLinkMaskedTextInput onChange={setValue} value={value} {...args} />}
    </Formik>
  );
}

export const Empty = Template.bind({});
Empty.args = {
  labelText: 'Label',
  mask: '99/99/9999',
  name: 'birthdate',
  required: false,
  placeholder: '01/01/1990',
};

export const Value = Template.bind({});
Value.args = {
  value: '12/02/1809',
  labelText: 'Label',
  mask: '99/99/9999',
  name: 'fortran',
  required: false,
  placeholder: '01/01/1990',
};
