import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkCheckboxes from 'react/shared/components/true_link/main/form/checkboxes/TrueLinkCheckboxes';
import NextPageButton from 'react/shared/components/true_link/main/form/wizard/NextPageButton';
import ErrorText from 'react/signups/card/common/ErrorText';

const useStyles = makeStyles({
  agreementCheckbox: {
    '& label': {
      border: 0,
      alignItems: 'start',
      '& p': {
        paddingTop: '5px',
      },
    },
  },
});

export default function AgreementsReviewForm({
  onContinue,
  msaLink,
  requiresCardholderAgreement,
  setFieldValue,
  submitting,
  values,
}) {
  const classes = useStyles();
  const handleAgreementCheck = (event) => {
    if (event.target.checked) {
      setFieldValue('agreements', values.agreements.concat(event.target.name));
    } else if (event.target.name === 'esignAgreement') {
      // The esign agreement must be agreed to first due to compliance reasons from Sunrise bank
      setFieldValue('agreements', []);
    } else {
      setFieldValue(
        'agreements',
        values.agreements.filter((name) => name !== event.target.name),
      );
    }
  };
  const agreementOptions = [
    {
      label: (
        <p>
          I have read and agree to the{' '}
          <TrueLinkLink href="/legal/esign-consent" rel="noreferrer" target="_blank">
            E-Sign Policy
          </TrueLinkLink>
          , which provides that True Link is an online service and that you will receive all account
          notices and information electronically via your primary email address.
        </p>
      ),
      value: 'esignAgreement',
    },
    {
      label: (
        <p>
          I have read and agree to the{' '}
          <TrueLinkLink href={msaLink} rel="noreferrer" target="_blank">
            Master Services Agreement
          </TrueLinkLink>{' '}
          which includes the{' '}
          <Box component="span" fontWeight="fontWeightBold">
            Cardholder Agreement, the Privacy Policy, and the True Link Software Protection
            Agreement, and True Link’s Privacy Policy and Terms of Service.
          </Box>
          .
        </p>
      ),
      value: 'msaAgreement',
      disabled: !values.agreements.includes('esignAgreement'),
    },
    requiresCardholderAgreement
      ? {
          label: (
            <p>
              I attest to the fact that the Cardholder has authorized me to order this True Link
              Visa Prepaid Card and to act as the Card Administrator.
            </p>
          ),
          value: 'cardholderAuthorized',
          disabled: !values.agreements.includes('esignAgreement'),
        }
      : null,
  ].filter(Boolean);

  return (
    <>
      <div className={classes.agreementCheckbox}>
        <TrueLinkCheckboxes
          ariaLabel="Sign required agreements"
          name="agreements"
          onChange={handleAgreementCheck}
          options={agreementOptions}
          required
          value={values.agreements}
        />
      </div>
      <ErrorText name="agreements" />
      <NextPageButton disabled={submitting} onClick={onContinue} />
    </>
  );
}

AgreementsReviewForm.propTypes = {
  msaLink: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  requiresCardholderAgreement: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    agreements: PropTypes.array.isRequired,
  }),
};
