import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { originTypes } from 'react/member/components/dashboard/reports/Constants';
import RadioDropdownCollectionsShape from 'react/member/components/dashboard/reports/RadioDropdownCollectionsShape';
import { originShape } from 'react/member/components/dashboard/reports/reports_section/OriginShape';
import TrueLinkRadiosWithDropdowns from 'react/shared/components/true_link/main/form/radios_with_dropdowns/TrueLinkRadiosWithDropdowns';

export default function EntityTypeRadios({
  entityCollections,
  onDropdownChange,
  onRadioChange,
  origin,
  reportSupportsSearchBy,
  value,
}) {
  // buildOptions: Builds and returns a list of radio button options, each with a label, value, and collection of items
  // for that radio's dropdown. If the `reportSupportsSearchBy` prop contains an entity and we have a collection for that entity
  // then that entity will be displayed as an option.
  const buildOptions = () => {
    const opts = [];

    if (origin.type === originTypes.organization) {
      if (reportSupportsSearchBy.organization) {
        const option = { label: 'The entire organization', value: originTypes.organization };
        opts.push(option);
      }
      if (reportSupportsSearchBy.trust && entityCollections[originTypes.trust]?.length) {
        const filteredTrusts = reportSupportsSearchBy.trustType
          ? entityCollections[originTypes.trust].filter(
              (trust) => trust.type === reportSupportsSearchBy.trustType,
            )
          : entityCollections[originTypes.trust];
        if (filteredTrusts.length) {
          opts.push({
            label: 'A specific trust',
            placeholder: 'Search for a trust',
            value: originTypes.trust,
            collection: filteredTrusts,
          });
        }
      }
      if (reportSupportsSearchBy.portfolio && entityCollections[originTypes.portfolio]?.length) {
        opts.push({
          label: 'A specific portfolio',
          placeholder: 'Search for a portfolio',
          value: originTypes.portfolio,
          collection: entityCollections[originTypes.portfolio],
        });
      }
      if (reportSupportsSearchBy.beneficiary && entityCollections[originTypes.bene]?.length) {
        const filteredBenes = reportSupportsSearchBy.trustType
          ? entityCollections[originTypes.bene].filter(
              (bene) => bene.trustType === reportSupportsSearchBy.trustType,
            )
          : entityCollections[originTypes.bene];
        opts.push({
          label: 'A specific beneficiary',
          placeholder: 'Search for a beneficiary',
          value: originTypes.bene,
          collection: filteredBenes,
        });
      }
    }

    return opts;
  };

  const [options] = useState(() => buildOptions());

  if (origin.type === originTypes.organization && options.length > 0) {
    return (
      <>
        <p>Export data for:</p>
        <TrueLinkRadiosWithDropdowns
          ariaLabel="Export data for"
          name="entityType"
          onDropdownChange={onDropdownChange}
          onRadioChange={onRadioChange}
          options={options}
          value={value}
        />
      </>
    );
  } else if ((origin.name && origin.name.trim().length > 0) || options.length > 0) {
    return <p>Export data for: {origin.name}</p>;
  }
}

EntityTypeRadios.propTypes = {
  entityCollections: RadioDropdownCollectionsShape.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  origin: originShape,
  reportSupportsSearchBy: PropTypes.shape({
    beneficiary: PropTypes.bool.isRequired,
    organization: PropTypes.bool.isRequired,
    portfolio: PropTypes.bool.isRequired,
    trust: PropTypes.bool.isRequired,
    trustType: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
};
