import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/text_input/TrueLinkTextInput';
import { smallBreakpointMediaQuery } from 'react/shared/theme/Theme';

export default function NameFields({ labelPrefix, onChange, setFieldTouched, values }) {
  const inline = useMediaQuery(smallBreakpointMediaQuery);

  return (
    <Grid container spacing={inline ? 3 : 0}>
      <Grid item sm={6} xs={12}>
        <TrueLinkTextInput
          labelText={labelPrefix ? `${labelPrefix} first name` : 'First name'}
          name="firstName"
          onChange={onChange}
          placeholder="First name"
          required
          setFieldTouched={setFieldTouched}
          value={values.firstName}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TrueLinkTextInput
          labelText={labelPrefix ? `${labelPrefix} last name` : 'Last name'}
          name="lastName"
          onChange={onChange}
          placeholder="Last name"
          required
          setFieldTouched={setFieldTouched}
          value={values.lastName}
        />
      </Grid>
    </Grid>
  );
}

NameFields.propTypes = {
  labelPrefix: PropTypes.oneOf(['Cardholder']),
  onChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
};
