/* eslint-disable no-empty-function */
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';
/*
 * @component
 * @example Picking a single date
 *   return (
 *    <DatePicker startDate="2020-01-01" />
 *   )
 *
 * @example Picking a date range
 *   return (
 *     <DatePicker startDate="2020-01-01" endDate="2020-03-30" />
 *   )
 */
//TODO: Update to functional component and use useRef
export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
    };

    if (this.props.startDate) {
      this.state['startDate'] = moment(this.props.startDate).format('YYYY-MM-DD');
    }

    if (this.props.endDate) {
      this.state['endDate'] = moment(this.props.endDate).format('YYYY-MM-DD');
    }

    bindAll(this);
  }

  componentDidMount() {
    this.initDatePicker();
  }

  initDatePicker() {
    const $dp = this.containerNode().find('.js-start-date, .js-end-date').datepicker({
      dateFormat: 'yy-mm-dd',
      maxDate: this.props.maxDate,
      minDate: this.props.minDate,
    });

    if (this.props.onChange) {
      $dp.on('change', this.props.onChange);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const targetElements = e.target.elements;
    const startDate =
      (targetElements.start_date && moment(targetElements.start_date.value).format()) || null;
    const endDate =
      (targetElements.end_date && moment(targetElements.end_date.value).format()) || null;

    this.props.onSubmit(startDate, endDate);
  }

  containerNode() {
    return $(this.container);
  }

  render() {
    const {
      action,
      showLabel,
      infoText,
      startDate,
      endDate,
      onSubmit,
      buttonType,
      buttonDisabled,
    } = this.props;

    const label = showLabel && (
      <div className="bold inline-block">{endDate ? 'Date range:' : 'Date:'}&nbsp;&nbsp;&nbsp;</div>
    );
    const buttonClass = `btn ${buttonType}`;

    const dateRangeElems = endDate && (
      <span>
        &nbsp; to &nbsp;
        <div className="datepicker-wrapper">
          <input
            autoComplete="off"
            className="date_input form-control js-end-date"
            name="end_date"
            onChange={() => {}}
            placeholder="Select end date"
            type="text"
            value={moment(endDate).format('YYYY-MM-DD')}
          />
        </div>
      </span>
    );

    return (
      <div ref={(input) => (this.container = input)}>
        <form id="date_form" method="get" onSubmit={this.onSubmit}>
          {label}
          {infoText && <InfoTooltip placement="bottom" tooltipText={infoText} />}

          <div className="datepicker-wrapper">
            <input
              autoComplete="off"
              className="date_input form-control js-start-date"
              name="start_date"
              onChange={() => {}}
              placeholder="Select start date"
              type="text"
              value={moment(startDate).format('YYYY-MM-DD')}
            />
          </div>

          {dateRangeElems}

          {onSubmit && (
            <TrueLinkButton
              className={buttonClass}
              disabled={buttonDisabled}
              style={{ marginLeft: 18, verticalAlign: 'top' }}
              type="submit"
              variant="none"
            >
              {action}
            </TrueLinkButton>
          )}
        </form>
      </div>
    );
  }
}

DatePicker.propTypes = {
  action: PropTypes.string,
  infoText: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  showLabel: PropTypes.bool,
  buttonType: PropTypes.string,
  buttonDisabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  action: 'Download',
  showLabel: true,
  buttonType: 'normal',
  buttonDisabled: false,
};
