import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardDepositFormAttachments from 'react/member/components/dashboard/deposits/DashboardDepositFormAttachments';
import FrequencyInputs from 'react/member/components/dashboard/disbursements/FrequencyInputs';
import DashboardDepositStore from 'react/member/stores/DashboardDepositStore';
import DashboardRecurringDepositStore from 'react/member/stores/DashboardRecurringDepositStore';
import Actions from 'react/shared/actions/actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import PaymentMethodDropdown from 'react/shared/components/PaymentMethodDropdown';
import DatePickerField from 'react/shared/components/forms/DatePickerField';
import Form from 'react/shared/components/forms/Form';
import PopUp from 'react/shared/components/popups/PopUp';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import HolidayCalendarStore from 'react/shared/stores/HolidayCalendarStore';
import { nextBusinessDay, validBusinessDate } from 'react/shared/utils/ValidBusinessDate';
import bindAll from 'react/shared/utils/bind_all';

export default class DepositPopUp extends React.Component {
  constructor(props) {
    super(props);

    const { deposit } = props;
    const depositStateFields = deposit
      ? {
          expectedAmount: deposit.expected_amount || '',
          amount: deposit.amount || '',
          paymentMethod: deposit.payment_method || '',
          paymentId: deposit.payment_id || '',
          isTaxable: deposit.is_taxable,
          selectedBeneficiaryId: deposit.trust_beneficiary_id || '',
          status: deposit.status,
        }
      : {};

    const recurringDepositStateFields =
      deposit && props.recurring
        ? {
            recurringType: deposit.recurring_type,
            day_of_week: deposit.day_of_week,
            day_of_month: deposit.day_of_month,
            day_of_month_2: deposit.day_of_month_2,
            start_date: deposit.start_date,
            end_date: deposit.end_date,
            budgetItemType: 'recurring',
            memo: deposit.memo,
            recurringStartDateError: '',
            recurringEndDateError: '',
          }
        : {};

    const oneTimeDepositStateFields =
      deposit && !props.recurring
        ? {
            expectedDeliveryDate: deposit.expected_delivery_date,
            deliveryDate: deposit.delivery_date,
            budgetItemType: 'one_time',
            merchant: deposit.merchant || '',
          }
        : {};

    const defaultDepositStateFields = {
      expectedAmount: '',
      expectedDeliveryDate: moment().format('YYYY-MM-DD'),
      paymentMethod: 'Check',
      paymentId: '',
      isTaxable: false,
      budgetItemType: 'one_time',
      merchant: '',
      memo: '',
      selectedBeneficiaryId: props.client ? props.client.id : '',
      attachmentIds: [],
      status: 'Pending',
    };
    this.state = {
      isReadOnly: this.readOnlyState(deposit),
      isButtonDisabled: false,
      selectedBeneficiaryError: '',
      ...defaultDepositStateFields,
      ...depositStateFields,
      ...oneTimeDepositStateFields,
      ...recurringDepositStateFields,
    };

    this._isMounted = false;

    bindAll(this);
  }

  componentDidMount() {
    this._isMounted = true;

    DashboardDepositStore.on('deposit.update', this.props.refreshDeposits);
    DashboardRecurringDepositStore.on('recurringDeposit.update', this.props.refreshDeposits);
    HolidayCalendarStore.on('bankHolidays.fetch', this.onBankHolidaysFetch);

    if (!HolidayCalendarStore.getBankHolidays()) {
      return Actions.fetchBankHolidays();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    DashboardDepositStore.off('deposit.update', this.props.refreshDeposits);
    DashboardRecurringDepositStore.off('recurringDeposit.update', this.props.refreshDeposits);
    HolidayCalendarStore.off('bankHolidays.fetch', this.onBankHolidaysFetch);
  }

  readOnlyState(deposit) {
    if (deposit) {
      if (deposit.read_only) {
        return true;
      }
      return !this.props.userCanEdit;
    }
    return false;
  }

  refreshDeposits() {
    this.props.closeModal();
    this.props.refreshDeposits();
    return Truelink.flash('success', 'Deposit cancelled.');
  }

  // We default expectedDeliveryDate to today before knowing if today is a weekend or
  // holiday. This method fires after we've loaded holiday information so we can pick
  // a valid day!
  onBankHolidaysFetch() {
    // deliveryDateError is set if a date has already been selected, so we don't want to change it anymore
    if (this.state.deliveryDateError) {
      return;
    }

    const { expectedDeliveryDate } = this.state;

    this.setState({
      expectedDeliveryDate: nextBusinessDay(expectedDeliveryDate),
    });
  }

  onDeliveryDateSelected(dateString) {
    this.setState({
      expectedDeliveryDate: dateString,
    });
    return this.validateDeliveryDate(dateString);
  }

  onDeliveryDateTyped(evt) {
    evt.preventDefault();
    this.onDeliveryDateSelected(evt.target.value);
  }

  validateSelectedBeneficiary(beneficiaryId) {
    const error =
      beneficiaryId || this.state.selectedBeneficiaryId ? '' : 'A beneficiary must be selected';
    this.setState({ selectedBeneficiaryError: error });

    return this.selectedBeneficiaryValid(error);
  }

  selectedBeneficiaryValid(selectedBeneficiaryError = this.state.selectedBeneficiaryError) {
    return !selectedBeneficiaryError;
  }

  validateDeliveryDate(dateString = this.state.expectedDeliveryDate) {
    if (this.state.budgetItemType === 'recurring') {
      return true;
    }

    const error = validBusinessDate(dateString)
      ? ''
      : 'Delivery Date must not be on a weekend or holiday';
    this.setState({ deliveryDateError: error });

    return this.deliveryDateValid(error);
  }

  deliveryDateValid(deliveryDateError = this.state.deliveryDateError) {
    if (this.state.budgetItemType === 'recurring') {
      return true;
    }
    return !deliveryDateError;
  }

  validateRecurringInputs() {
    return this.recurringInputsValid();
  }

  recurringInputsValid() {
    const { recurringStartDateError, recurringEndDateError } = this.state;
    if (this.state.budgetItemType === 'one_time') {
      return true;
    }
    return !recurringStartDateError && !recurringEndDateError;
  }

  isValid() {
    return (
      this.selectedBeneficiaryValid() && this.deliveryDateValid() && this.recurringInputsValid()
    );
  }

  disableScroll(evt) {
    $(evt.target).blur();
  }

  updateExpectedAmount(evt) {
    return this.setState({ expectedAmount: evt.target.value });
  }

  updatePaymentMethod(evt) {
    return this.setState({ paymentMethod: evt.target.value });
  }

  updatePaymentId(evt) {
    return this.setState({ paymentId: evt.target.value });
  }

  setBudgetItemType(evt) {
    return this.setState({ budgetItemType: evt.target.value });
  }

  updateRecurringDepositInputs(inputs) {
    return this.setState(inputs);
  }

  updateSelectedBeneficiaryId(evt) {
    this.setState({ selectedBeneficiaryId: evt.target.value });
    return this.validateSelectedBeneficiary(evt.target.value);
  }

  updateMerchant(evt) {
    return this.setState({ merchant: evt.target.value });
  }

  updateMemo(evt) {
    return this.setState({ memo: evt.target.value });
  }

  sendEditDepositRequest() {
    const { slug } = this.props.deposit;
    const deposit = {
      amount: { amount: Number.parseFloat(this.state.expectedAmount), currency: 'USD' },
      deliveryDate: this.state.expectedDeliveryDate,
      paymentMethod: this.state.paymentMethod,
      paymentId: this.state.paymentId,
      isTaxable: this.state.isTaxable,
      status: this.state.status,
      merchant: this.state.merchant,
    };
    const path = RailsRoutes.api_v2_deposit_path(slug, { format: 'json' });
    return $.ajax({
      url: path,
      type: 'PATCH',
      data: JSON.stringify({ data: { id: slug, type: 'deposit', attributes: deposit } }),
      dataType: 'json',
      contentType: 'application/json',
      success: this.editCallback,
      error: this.failCallback,
    });
  }

  sendDepositRequest() {
    const deposit = {
      amount: { amount: Number.parseFloat(this.state.expectedAmount), currency: 'USD' },
      deliveryDate: this.state.expectedDeliveryDate,
      paymentMethod: this.state.paymentMethod,
      paymentId: this.state.paymentId,
      isTaxable: this.state.isTaxable,
      status: this.state.status,
      merchant: this.state.merchant,
    };
    const path = RailsRoutes.api_v2_trust_beneficiary_deposits_path(
      this.state.selectedBeneficiaryId,
    );
    return $.ajax({
      url: path,
      type: 'POST',
      data: JSON.stringify({
        data: {
          type: 'deposit',
          attributes: deposit,
          relationships: {
            attachments: {
              data: this.state.attachmentIds.map((attId) => ({ id: attId, type: 'attachment' })),
            },
          },
        },
      }),
      dataType: 'json',
      contentType: 'application/json',
      success: this.createCallback,
      error: this.failCallback,
    });
  }

  sendEditRecurringDepositRequest() {
    let request;
    switch (this.state.recurringType) {
      case 'every_n_weeks':
        request = {
          day_of_week: this.state.day_of_week,
        };
        break;
      case 'every_n_months':
        request = {
          day_of_month: this.state.day_of_month,
        };
        break;
      case 'twice_monthly_every_n_months':
        request = {
          day_of_month: this.state.day_of_month,
          day_of_month_2: this.state.day_of_month_2,
        };
        break;
    }
    const universalData = {
      trust_beneficiary_id: this.state.selectedBeneficiaryId,
      frequency: this.state.frequency,
      amount: this.state.expectedAmount,
      payment_method: this.state.paymentMethod,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      payment_id: this.state.paymentId,
      is_taxable: this.state.isTaxable,
      recurring_type: this.state.recurringType,
      memo: this.state.memo,
    };
    const recurringDepositRequest = _extend(request, universalData);
    const path = RailsRoutes.dashboard_recurring_deposit_path(this.props.deposit.id, {
      format: 'json',
    });
    return $.ajax({
      url: path,
      type: 'PUT',
      data: { recurring_deposit: recurringDepositRequest },
      success: this.editCallback,
      error: this.failCallback,
    });
  }

  sendRecurringDepositRequest() {
    let request;
    switch (this.state.recurringType) {
      case 'every_n_weeks':
        request = {
          day_of_week: this.state.day_of_week,
        };
        break;
      case 'every_n_months':
        request = {
          day_of_month: this.state.day_of_month,
        };
        break;
      case 'twice_monthly_every_n_months':
        request = {
          day_of_month: this.state.day_of_month,
          day_of_month_2: this.state.day_of_month_2,
        };
        break;
    }
    const universalData = {
      trust_beneficiary_id: this.state.selectedBeneficiaryId,
      frequency: this.state.frequency,
      amount: this.state.expectedAmount,
      payment_method: this.state.paymentMethod,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      payment_id: this.state.paymentId,
      is_taxable: this.state.isTaxable,
      recurring_type: this.state.recurringType,
      memo: this.state.memo,
      attachment_ids: this.state.attachmentIds,
    };
    const recurringDepositRequest = _extend(request, universalData);
    const path = RailsRoutes.dashboard_recurring_deposits_path({ format: 'json' });
    return $.ajax({
      url: path,
      type: 'POST',
      data: { recurring_deposit: recurringDepositRequest },
      success: this.createCallback,
      error: this.failCallback,
    });
  }

  hasErrors() {
    return !this.state.selectedBeneficiaryError && !this.state.validateDeliveryDate();
  }

  validateAndSendRequest() {
    return (
      this.validateSelectedBeneficiary() &&
      this.validateDeliveryDate() &&
      this.validateRecurringInputs() &&
      this.sendRequest()
    );
  }

  sendRequest() {
    this.setState({ isButtonDisabled: true });

    if (!this.props.newDepositForm) {
      if (this.state.budgetItemType === 'one_time') {
        this.sendEditDepositRequest();
      }
      if (this.state.budgetItemType === 'recurring') {
        return this.sendEditRecurringDepositRequest();
      }
    } else {
      if (this.state.budgetItemType === 'one_time') {
        this.sendDepositRequest();
      }
      if (this.state.budgetItemType === 'recurring') {
        return this.sendRecurringDepositRequest();
      }
    }
  }

  createCallback() {
    let message;
    this.props.closeModal();
    if (this._isMounted) {
      this.setState({ isButtonDisabled: false });
    }

    if (this.state.budgetItemType === 'one_time') {
      message = 'Pending deposit created.';
      this.props.refreshDeposits();
    } else {
      message = 'Recurring Deposit created.';
      this.props.refreshDeposits();
    }

    return Truelink.flash('success', message);
  }

  editCallback() {
    let message;
    this.props.closeModal();
    if (this.props.recurring) {
      message = 'Recurring Deposit edited.';
      this.props.refreshDeposits();
    } else {
      message = 'Deposit edited.';
      this.props.refreshDeposits();
    }
    return Truelink.flash('success', message);
  }

  failCallback() {
    Truelink.flash('error', 'An Error has occurred.');
    if (this._isMounted) {
      return this.setState({ isButtonDisabled: false });
    }
  }

  openCancelModal() {
    const id = this.props.deposit ? this.props.deposit.id : undefined;
    const beneficiaryId = this.state.selectedBeneficiaryId;
    DashboardActions.openDepositCancelModal(id, this.state.budgetItemType, beneficiaryId);
  }

  onAttachmentUpload(attachment) {
    this.setState((prevState) => ({
      attachmentIds: prevState.attachmentIds.concat(attachment.id),
    }));
  }

  renderDepositTypeInputs() {
    const deposit = this.props.deposit ? this.props.deposit : {};
    if (this.state.budgetItemType === 'one_time') {
      return (
        <div className="new-form__data__subsection new-form__data__subsection--border-bottom">
          <div className="new-form__label">Expected Delivery Date:</div>
          <div className="new-form__data">
            <DatePickerField
              dateFormat="yy-mm-dd"
              defaultDate={this.state.expectedDeliveryDate}
              excludeWeekendsAndHolidays
              handleDateSelect={this.onDeliveryDateSelected}
              inputProps={{
                name: 'expected_delivery_date',
                id: 'one_time_delivery_date',
                disabled: this.state.isReadOnly,
                value: this.state.expectedDeliveryDate,
                onChange: this.onDeliveryDateTyped,
              }}
              minDate={new Date()}
            />
            {!this.state.isReadOnly && (
              <>
                <br />
                <div className="new-form__callout" style={{ marginTop: 7 }}>
                  Select the date you expect the
                  <br />
                  deposit to arrive at the bank.
                </div>
              </>
            )}
          </div>
          <div className="new-form__error" id="delivery-date-error">
            {this.state.deliveryDateError}
          </div>
          {this.state.isReadOnly && (
            <>
              <div className="new-form__label">Delivery Date:</div>
              <div className="new-form__data">{this.state.deliveryDate}</div>
            </>
          )}
        </div>
      );
    } else if (this.state.budgetItemType === 'recurring') {
      return (
        <div className="new-form__data__subsection new-form__data__subsection--border-bottom">
          <FrequencyInputs
            formData={deposit}
            maxDate=""
            subclass="deposit"
            updateRecurringDeposit={this.updateRecurringDepositInputs}
          />
        </div>
      );
    }
  }

  renderDescriptionInput() {
    if (this.state.budgetItemType === 'one_time') {
      return (
        <input
          disabled={this.state.isReadOnly}
          name="merchant"
          onChange={this.updateMerchant}
          type="text"
          value={this.state.merchant || ''}
        />
      );
    } else if (this.state.budgetItemType === 'recurring') {
      return (
        <input
          disabled={this.state.isReadOnly}
          name="memo"
          onChange={this.updateMemo}
          type="text"
          value={this.state.memo || ''}
        />
      );
    }
  }

  addChosen() {
    return $('#deposit-beneficiary').chosen().on('change', this.updateSelectedBeneficiaryId);
  }

  client() {
    if (this.props.client) {
      return <span>{this.props.client.person.name}</span>;
    } else if (this.props.clientListLoading) {
      return (
        <div>
          <LoadingIndicator containerStyle={{ textAlign: 'left' }} />
        </div>
      );
    }
    return (
      <select
        defaultValue={this.state.selectedBeneficiaryId}
        disabled={this.state.isReadOnly}
        id="deposit-beneficiary"
        name="trust_beneficiary_id"
        ref={this.addChosen}
      >
        {this.clients()}
      </select>
    );
  }

  clients() {
    const bene_options = this.props.trustBeneficiaries.map((beneficiary) => (
      <option key={`client_${beneficiary.id}`} value={beneficiary.id}>
        {beneficiary.person.name}
      </option>
    ));
    return [<option key="client_none" value="" />].concat(bene_options);
  }

  modalFooter() {
    const { newDepositForm, closeModal } = this.props;
    const { isReadOnly, isButtonDisabled } = this.state;
    const cancelDepositButton =
      !newDepositForm && !isReadOnly ? (
        <button className="normal btn btn-danger" onClick={this.openCancelModal} type="button">
          Cancel Deposit
        </button>
      ) : null;
    const closeModalButton = (
      <button className="btn btn-default cancel" onClick={closeModal} type="button">
        Cancel
      </button>
    );
    return (
      <div className="flex flex--space-between">
        {cancelDepositButton || closeModalButton}
        {!isReadOnly && (
          <button
            className="normal btn btn-alert"
            disabled={!this.isValid() || isButtonDisabled}
            onClick={this.validateAndSendRequest}
            type="button"
          >
            Save
          </button>
        )}
      </div>
    );
  }

  modalContent() {
    const { trustBeneficiaries, newDepositForm, displayedPaymentMethods, deposit, userCanEdit } =
      this.props;
    const { isReadOnly, budgetItemType, expectedAmount, paymentId, paymentMethod } = this.state;

    const budgetItemTypeInputs = newDepositForm ? (
      <div className="new-form__data" style={{ marginBottom: 10, marginTop: -7 }}>
        <div className="radio-group radio-group--inline">
          <label htmlFor="one_time_budget_item_type">
            <input
              checked={budgetItemType == 'one_time'}
              id="one_time_budget_item_type"
              name="budget_item_type"
              onChange={this.setBudgetItemType}
              type="radio"
              value="one_time"
            />
            One-Time
          </label>
          <label htmlFor="recurring_budget_item_type">
            <input
              checked={budgetItemType == 'recurring'}
              id="recurring_budget_item_type"
              name="budget_item_type"
              onChange={this.setBudgetItemType}
              type="radio"
              value="recurring"
            />
            Recurring
          </label>
        </div>
      </div>
    ) : null;
    return (
      <div>
        <Form ref={(input) => (this.form = input)}>
          <div className="new-form__label">Beneficiary:</div>
          <div
            className="new-form__data"
            id="deposit-clients-select-container"
            key={`beneficiary-select-${trustBeneficiaries.length}`}
          >
            {this.client()}
          </div>
          <div className="new-form__error" id="client_id_error">
            {this.state.selectedBeneficiaryError}
          </div>
          <div className="new-form__label padding-right-0">Expected Amount:</div>
          <div className="new-form__data">
            <input
              className="non-scroll"
              disabled={isReadOnly}
              name="expected_amount"
              onChange={this.updateExpectedAmount}
              onWheel={this.disableScroll}
              step="0.01"
              type="number"
              value={expectedAmount}
            />
            <br />
            {!isReadOnly ? (
              <div className="new-form__callout" style={{ marginTop: 7 }}>
                Enter the expected amount for the deposit.
              </div>
            ) : null}
          </div>
          {isReadOnly && (
            <div className="new-form__data__subsection">
              <div className="new-form__label">Amount:</div>
              <div className="new-form__data">{this.state.amount}</div>
            </div>
          )}
          <div className="new-form__label" style={{ marginBottom: 10 }}>
            Schedule:
          </div>
          {budgetItemTypeInputs}
          {this.renderDepositTypeInputs()}
          {isReadOnly ? (
            <div>
              <div className="new-form__label">Payment Type:</div>
              <div className="new-form__data">
                <input
                  disabled
                  name="payment_method"
                  style={{ marginBottom: '2px' }}
                  type="text"
                  value={paymentMethod || ''}
                />
              </div>
            </div>
          ) : (
            <PaymentMethodDropdown
              displayedPaymentMethods={displayedPaymentMethods}
              handlePaymentMethod={this.updatePaymentMethod}
              hideLabel={false}
              isDeposit
              isEditable
              label="Payment Type:"
              paymentMethod={paymentMethod}
            />
          )}
          <div className="new-form__label">Payment ID:</div>
          <div className="new-form__data">
            <input
              disabled={this.state.isReadOnly}
              name="payment_id"
              onChange={this.updatePaymentId}
              style={{ marginBottom: '2px' }}
              type="text"
              value={paymentId || ''}
            />
            <br />
            <div className="new-form__callout" style={{ marginTop: 7 }}>
              Payment ID should reflect the check number or
              <br />
              wire/EFT ID associated with the deposit.
            </div>
          </div>
          <div className="new-form__label">Description:</div>
          <div className="new-form__data">
            {this.renderDescriptionInput()}
            <InfoTooltip
              placement="bottom"
              tooltipText="Description should reflect any relevant information about the funding source."
            />
          </div>
          <DashboardDepositFormAttachments
            deposit={deposit}
            onUpload={this.onAttachmentUpload}
            userCanEdit={userCanEdit || newDepositForm}
          />
        </Form>
      </div>
    );
  }

  render() {
    const { newDepositForm, modalOpen, closeModal } = this.props;

    const width = this.state.budgetItemType === 'recurring' ? '830px' : '630px';
    let headerText;
    if (!newDepositForm && !this.state.isReadOnly) {
      headerText = 'Edit Deposit Record';
    } else if (newDepositForm) {
      headerText = 'Add Deposit Record';
    } else {
      headerText = 'View Deposit Details';
    }

    return (
      <PopUp
        footer={this.modalFooter()}
        header={headerText}
        maxWidth={width}
        modalOpen={modalOpen}
        onClose={closeModal}
      >
        {this.modalContent()}
      </PopUp>
    );
  }
}

DepositPopUp.propTypes = {
  client: PropTypes.object,
  deposit: PropTypes.object,
  recurring: PropTypes.bool,
  displayedPaymentMethods: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  newDepositForm: PropTypes.bool,
  refreshDeposits: PropTypes.func,
  trustBeneficiaries: PropTypes.array,
  userCanEdit: PropTypes.bool,
  clientListLoading: PropTypes.bool,
};

DepositPopUp.defaultProps = {
  displayedPaymentMethods: ['Check', 'EFT', 'Wire'],
  recurring: false,
  newDepositForm: true,
  refreshDeposits: () => {
    // Do nothing by default
  },
  trustBeneficiaries: [],
};
