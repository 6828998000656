import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useFocus from './UseFocus';
import TrueLinkMaskedTextInput from 'react/shared/components/true_link/main/form/masked_text_input/TrueLinkMaskedTextInput';
import TrueLinkTextInput from 'react/shared/components/true_link/main/form/text_input/TrueLinkTextInput';
import StateDropdown from 'react/signups/card/common/StateDropdown';
import addressShape from 'react/signups/card/shapes/AddressShape';

export default function ManualEntryAddressInput({
  values,
  labelPrefix,
  setFieldTouched,
  street1Adornment,
  onChange,
}) {
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    setInputFocus();
  }, [setInputFocus]);

  return (
    <>
      <TrueLinkTextInput
        inputProps={{ maxLength: 30 }}
        labelAdornment={street1Adornment}
        labelText={labelPrefix ? `${labelPrefix} address line 1` : 'Address line 1'}
        name="address.street1"
        onChange={onChange}
        placeholder="Street address"
        ref={inputRef}
        required
        setFieldTouched={setFieldTouched}
        value={values.address.street1 || ''}
      />
      <TrueLinkTextInput
        inputProps={{ maxLength: 30 }}
        labelText={labelPrefix ? `${labelPrefix} address line 2` : 'Address line 2'}
        name="address.street2"
        onChange={onChange}
        placeholder="Suite or apartment number"
        required
        setFieldTouched={setFieldTouched}
        value={values.address.street2 || ''}
      />
      <TrueLinkTextInput
        inputProps={{ maxLength: 40 }}
        labelText="City"
        name="address.city"
        onChange={onChange}
        placeholder="City"
        required
        setFieldTouched={setFieldTouched}
        value={values.address.city || ''}
      />
      <StateDropdown onChange={onChange} setFieldTouched={setFieldTouched} values={values} />
      <TrueLinkMaskedTextInput
        labelText="Zip"
        mask="99999"
        name="address.zip"
        onChange={onChange}
        placeholder="Zip"
        required
        setFieldTouched={setFieldTouched}
        value={values.address.zip || ''}
      />
    </>
  );
}

ManualEntryAddressInput.propTypes = {
  labelPrefix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  street1Adornment: PropTypes.node,
  values: addressShape.isRequired,
};
