import PropTypes from 'prop-types';
import _each from 'underscore/modules/each';
import _extend from 'underscore/modules/extend';
import _map from 'underscore/modules/map';
import _mapObject from 'underscore/modules/mapObject';
import _union from 'underscore/modules/union';
import _without from 'underscore/modules/without';
import { destroy, find, list } from 'react/shared/utils/RailsRoutes';

const Controller = {
  getControllerInitialState(initial) {
    // we do not set object initially, because it first comes from props
    return _extend(
      {
        form: {},
        editable: false,
        changed: false,
        fields: {},
        customSerializers: {},
      },
      initial || {},
    );
  },

  getControllerPropTypes() {
    return {
      editable: PropTypes.bool,
    };
  },

  addField(name, field) {
    const { fields } = this.state;
    fields[name] = field;
    this.setState({ fields });
  },

  get(name) {
    if (this.object()[name] === undefined) {
      return this.props[name];
    }
    return this.object()[name];
  },

  getField(name) {
    return this.state.fields[name];
  },

  getFieldValue(name) {
    const field = this.getField(name);
    if (field) {
      return field.value();
    }
  },

  setFieldValue(name, value) {
    const field = this.getField(name);
    if (field) {
      field.setState({ value });
    }
  },

  set(name, value, changed) {
    // Ideally this would be tighter controlled, but it is legacy
    // eslint-disable-next-line no-eq-null
    const chg = { object: this.object(), changed: changed == null ? true : changed };
    chg.object[name] = value;
    this.setState(chg);
    // if we have a binding, update it's state, too
    const binding = this.state.fields[name];
    if (binding) {
      binding.set(value);
    }
  },

  setObject(object) {
    this.setState({ object });
    const { fields } = this.state;
    _map(fields, (field, name) => this.set(name, this.get(name)));
  },

  changed() {
    return this.state.changed;
  },

  editable() {
    return this.state.editable || this.is_new();
  },

  is_new() {
    return this.props.action === 'new' && !this.object().id;
  },

  formId() {
    return `${this.model()}_${this.id()}`;
  },

  addCustomSerializer(name, serializer) {
    const serializers = this.state.customSerializers;
    serializers[name] = serializer;
    this.setState({ serializers });
  },

  serializeFormAsArray() {
    // create a hash of form data
    const pairs = $(this.form.form).serializeArray();
    const data = {};
    _each(pairs, (pair) => {
      if (pair.name.endsWith('[]')) {
        if (data[pair.name] === undefined) {
          data[pair.name] = [];
        }
        return data[pair.name].push(pair.value);
      }
      return (data[pair.name] = pair.value);
    });
    return data;
  },

  // we have to do some custom serialization, because jQuery and Rails do not agree
  // on how to handle checkboxes
  serializeForm() {
    const data = this.serializeFormAsArray();
    // now make sure checkboxes are in there
    const checkboxes = $('input[type="checkbox"][data-checkbox-style="single"]', this.form.form);
    _map(checkboxes, (checkbox) => (data[checkbox.name] = checkbox.checked ? '1' : '0'));
    // now make sure options are in there
    const options = $('input[type="checkbox"][data-checkbox-style="multiple"]', this.form.form);
    // first set empty arrays for options
    _each(options, (option) => (data[option.name] = ['']));
    _each(
      options,
      (option) =>
        (data[option.name] = option.checked
          ? _union(data[option.name], [option.value])
          : _without(data[option.name], option.value)),
    );
    // do similar for multiselect dropdowns
    const multiselects = $('select[multiple]', this.form.form);
    // first set empty arrays for multiselects
    _each(multiselects, (multiselect) => (data[multiselect.name] = ['']));
    _each(multiselects, (multiselect) => {
      const values = $(multiselect).val();
      // eslint-disable-next-line no-constant-binary-expression
      if (values && values.length > 0 && values !== ['']) {
        return (data[multiselect.name] = values);
      }
    });

    _mapObject(
      this.state.customSerializers,
      (serializer, inputName) => (data[inputName] = serializer()),
    );

    return $.param(data);
  },

  url() {
    const model = this.model();
    if (this.props.action === 'edit') {
      return this.saveRoute();
    }
    return list(model)();
  },

  saveRoute() {
    const model = this.model();
    if (this.id() === undefined) {
      return list(model)();
    }
    return find(model)(this.id(), { format: 'json' });
  },

  method() {
    switch (this.props.action) {
      case 'edit': {
        return 'PUT';
      }
      case 'destroy': {
        return 'DELETE';
      }
      case 'new': {
        return 'POST';
      }
      default:
        return 'GET';
    }
  },

  model() {
    return this.props.model;
  },

  object() {
    if (this.state.object !== undefined) {
      return this.state.object;
    }
    return this.props.object;
  },

  id() {
    if (this.object()) {
      return this.object().id;
    }
  },

  load() {
    return $.get(find(this.model())(this.object().id, { format: 'json' })).done((newObject) => {
      this.setObject(newObject);
      this.setState({ changed: false });
    });
  },

  valid() {
    return $(this.form.form).valid() && (!this.customValidation || this.customValidation());
  },

  save(cb) {
    if (this.changed() && this.valid()) {
      const was_new = this.is_new();
      return $.ajax({
        url: this.url(),
        data: this.serializeForm(),
        type: this.method(),
        dataType: 'json',
      }).done((object) => {
        if (object) {
          this.setObject(object);
        }
        this.setState({ changed: false });
        if (cb !== undefined) {
          cb(object);
          if (was_new && !this.props.doNotRedirectOnSave) {
            return (window.location.href = find(this.model())(object.id));
          }
        }
      });
    }
    this.setState({ editable: !this.state.editable });
  },

  destroy() {
    if (!this.is_new()) {
      const url = destroy(this.model(), this.id());
      return $.ajax({ url, method: 'DELETE', dataType: 'json' }).done(() => {
        if (this.props.afterDestroy) {
          this.props.afterDestroy();
        }

        if (!this.props.doNotRedirectOnSave) {
          return (window.location.href = list(this.model())());
        }
      });
    }
  },

  toggleEdit(evt) {
    if (evt) {
      evt.preventDefault();
    }

    if (this.editable() && this.valid()) {
      return this.save((obj) => {
        if (this.actions && typeof this.actions.saved === 'function') {
          this.actions.saved(obj);
        }
        this.setState({ editable: false });
      });
    }
    if (this.actions && typeof this.actions.reset === 'function') {
      this.actions.reset();
    }

    this.setState({ editable: true });
  },
};

export default Controller;
