const organization = 'Organization';
const trustBeneficiary = 'TrustBeneficiary';
const portfolio = 'Portfolio';
const trust = 'Trust';
const pooledTrust = 'PooledTrust';
const standaloneTrust = 'IndividualTrust';
const allPooledTrusts = 'AllPooledTrusts';
const allStandaloneTrusts = 'AllStandaloneTrusts';

// "Origin" refers to the context that the reports are being generated in. A user can view the
// reports tab from their org dashboard, or for a specific bene or portfolio
export const originTypes = {
  bene: trustBeneficiary,
  organization,
  portfolio,
  trust, // trust is not a valid origin type and usage should be cleaned up with the `bulk_trust_reporting` flipper
};

// "Entity" refers to the org, beneficiary, portfolio, or trust that the report will contain data for
export const entityTypes = {
  trustBeneficiary,
  portfolio,
  trust,
  pooledTrust,
  standaloneTrust,
  organization,
};

export const entityTypeLabels = {
  [entityTypes.organization]: organization,
  [entityTypes.trustBeneficiary]: 'trust beneficiary',
  [entityTypes.portfolio]: portfolio,
  [entityTypes.pooledTrust]: 'pooled trust',
  [entityTypes.standaloneTrust]: 'standalone account',
};

export const entityValues = {
  organization,
  trustBeneficiary,
  portfolio,
  pooledTrust,
  standaloneTrust,
  allPooledTrusts,
  allStandaloneTrusts,
};

export const reportTargetTypeOptions = {
  organization: {
    label: 'Entire organization',
    value: entityValues.organization,
    targetType: organization,
  },
  trustBeneficiary: {
    label: 'Specific beneficiary',
    value: entityValues.trustBeneficiary,
    targetType: trustBeneficiary,
  },
  portfolio: {
    label: 'Specific portfolio',
    value: entityValues.portfolio,
    targetType: portfolio,
  },
  allPooledTrusts: {
    label: 'All pooled trusts',
    value: entityValues.allPooledTrusts,
    targetSubset: pooledTrust,
    targetType: organization,
  },
  allStandaloneTrusts: {
    label: 'All standalone accounts',
    value: entityValues.allStandaloneTrusts,
    targetSubset: standaloneTrust,
    targetType: organization,
  },
  standaloneTrust: {
    label: 'Specific standalone account',
    value: entityValues.standaloneTrust,
    targetType: standaloneTrust,
  },
  pooledTrust: {
    label: 'Specific pooled trust',
    value: entityValues.pooledTrust,
    targetType: pooledTrust,
  },
};

export const reportNames = {
  customStatements: 'Custom time period statements',
  quarterlyStatements: 'Quarterly statements',
  disbursementsByCategory: 'Disbursements by category',
};
