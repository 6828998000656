import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import TrueLinkNumericCode from 'react/shared/components/true_link/main/form/numeric_code/TrueLinkNumericCode';

export default function AccessCodeModal({ userId, onClose }) {
  const queryClient = useQueryClient();
  const [editingMode, setEditingMode] = useState(false);
  const [editedAccessCode, setEditedAccessCode] = useState('');
  const {
    isLoading,
    data: retrievalResponse,
    error: retrievalError,
  } = useQuery({
    queryKey: ['access_codes', 'filterByUserId', userId],
    queryFn: async () => {
      const response = await axios.get(RailsRoutes.api_v2_access_codes_path({ userId }));
      return response.data;
    },
  });

  const mutation = useMutation(
    ({ code, id }) =>
      axios.patch(RailsRoutes.api_v2_access_code_path(id), {
        data: {
          type: 'access_code',
          id,
          attributes: {
            code,
          },
        },
      }),
    {
      onSuccess: () => {
        // Normally `invalidateQueries` would be preferable, but since ReactCodeInput has bugs in updating it is
        // better to just remove the query and let it be re-fetched in this case.
        queryClient.removeQueries(['access_codes', 'filterByUserId', userId]);
        setEditedAccessCode('');
        setEditingMode(false);
      },
      onError: (error) => {
        Truelink.flash('error', `An error occurred while saving your new access code. (${error})`);
      },
    },
  );

  const accessCodeId = retrievalResponse?.data[0]?.id || null;
  const remoteAccessCode = retrievalResponse?.data[0]?.attributes?.code || null;

  const handleSave = useCallback(() => {
    mutation.mutate({ code: editedAccessCode, id: accessCodeId });
  }, [mutation, editedAccessCode, accessCodeId]);

  const handleSwitchToEdit = useCallback(() => {
    setEditingMode(true);
  }, []);

  const handleSwitchToView = useCallback(() => {
    setEditingMode(false);
  }, []);

  const viewAccessCodeComponent = (
    <>
      <div>
        We use Access Code to verify your identity when you call us about your True Link accounts.
        Never share your Access Code with anyone.
      </div>
      <div style={{ marginTop: '20px', fontSize: '16px' }}>
        <b style={{ marginRight: '10px' }}>Current Access Code</b>
        {remoteAccessCode && <TrueLinkLink onClick={handleSwitchToEdit}>Update</TrueLinkLink>}
      </div>
      <div
        style={{
          marginTop: '20px',
          marginLeft: '5px',
          display: 'block',
          fontFamily: 'monospace',
          fontWeight: 'bold',
          fontSize: '40px',
          letterSpacing: '10px',
        }}
      >
        {remoteAccessCode}
      </div>
      {retrievalError && (
        <p className="error">
          An error occurred while retrieving your access code: {retrievalError}
        </p>
      )}
      {isLoading && <CircularProgress />}
    </>
  );

  const editAccessCodeComponent = (
    <>
      <div>To change your Access Code enter a new 4 digit code.</div>
      <div style={{ marginTop: '20px', fontSize: '16px' }}>
        <b>Access Code</b>
      </div>
      <TrueLinkNumericCode
        fields={4}
        isValid
        onChange={setEditedAccessCode}
        style={{ marginBottom: '20px' }}
        value={editedAccessCode}
      />
      <div style={{ marginTop: 'auto' }}>
        <TrueLinkButton
          disabled={mutation.isLoading || 4 !== editedAccessCode?.length}
          onClick={handleSave}
          style={{ marginRight: '20px' }}
          variant="primary"
        >
          Update
        </TrueLinkButton>
        <TrueLinkLink onClick={handleSwitchToView}>Back</TrueLinkLink>
      </div>
    </>
  );

  return (
    <PopUp
      bodyHeight="48vh"
      header={
        <div style={{ width: '100%', marginLeft: '0%' }}>
          <VerifiedUserOutlinedIcon sx={{ marginRight: '10px', verticalAlign: '-.125em' }} />
          Access Code
        </div>
      }
      hideFooter
      id="access_code_dialog"
      maxHeight="300px"
      maxWidth="620px"
      modalOpen
      onClose={onClose}
    >
      <div
        style={{
          minHeight: '180px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {editingMode ? editAccessCodeComponent : viewAccessCodeComponent}
      </div>
    </PopUp>
  );
}

AccessCodeModal.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
